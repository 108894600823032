import { ref } from 'vue'
import { Router } from 'vue-router'

const router = ref<Router | null>(null)

export const registerRouter = (routerInstance: Router) => {
  router.value = routerInstance
}

export default router

import { createRouter, createWebHistory } from 'vue-router'
import { registerRouter } from '~/router/router'
import routes from './routes'

const router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,

  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createWebHistory(process.env.VUE_ROUTER_BASE),
})

registerRouter(router)

export default router
